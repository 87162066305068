
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { NavBar, Button, Cell, CellGroup, Image as VanImag } from 'vant'
import { setToken, removeToken, getAppType } from '@/utils/cookies'
import { loginService } from '@api/mine/login/index.api'
import { UserService } from '@api/mine/UserService/index.api'

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImag.name]: VanImag
  }
})
export default class mine extends Vue {
  @Mutation('setUserInfo') public setUserInfo: any
  @Getter('getUserInfo') getUserInfo

  public userInfo = {}

  public points = {}

  public unrealized() {
    this.$toast('功能尚在建设中 · · · ')
  }

  public callTel(tel) {
    location.href = `tel:${tel}`
  }

  public orderStatusList = [
    {
      title: '待入住',
      status: 'CanTravel',
      img: require('@/assets/image/delivery.png')
    },
    {
      title: '待支付',
      status: 'CanPay',
      img: require('@/assets/image/unpaid.png')
    },
    {
      title: '已入住',
      status: 'Traveled',
      img: require('@/assets/image/evaluate.png')
    },
    {
      title: '退款/售后',
      status: 'Return',
      img: require('@/assets/image/afterSale.png')
    }
  ]

  public toOrderPage(status) {
    this.$router.push({
      path: '/order',
      query: {
        status: status
      }
    })
  }

  async getPromise() {
    let {
      data: { ReturnJson }
    } = await loginService({
      Action: 'GetWeChatLoginUrl',
      AppType: getAppType()
    })
    window.location.href = JSON.parse(ReturnJson)
  }

  @Watch('$route.query', { deep: true, immediate: false }) watchQuery(newVal) {
    if (newVal && newVal.logincode) {
      this.getWXAuth(newVal.logincode)
    }
  }

  async getWXAuth(code) {
    let { data } = await loginService({
      Action: 'LoginByWeChat',
      AppType: getAppType(),
      Code: code
    })

    if (data.Token) {
      this.setUserInfo(data.ReturnJson)
      setToken(data.Token)
      this.userInfo = data.ReturnJson
    }
  }

  login() {
    this.getPromise()
  }

  public async getPoints() {
    try {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      let {
        data: { ReturnJson }
      } = await UserService({ Action: 'GetNingMengUserData' })

      this.points = JSON.parse(ReturnJson)
    } finally {
      this.$toast.clear()
    }
  }

  public onPushPoints() {
    this.$router.push({
      path: '/mine/points'
    })
  }

  created() {
    this.userInfo = this.getUserInfo || ''
    this.getPoints()
  }
}
